export const DONATIONS = {
  'BTC': 'bc1qzqtkcf28ufrr6dh3822vcz6ru8ggmvgj3uz903',
  'LTC': 'ltc1q8krf9g60n4q6dvnwg3lg30lp5e7yfvm2da5ty5',
  'BNB': 'bnb1u5k33qnmcmgzyztaj9x89wzrnavxqls8d4x08h',
  'RVN': 'RVZD5AjUBXoNnsBg9B2AzTTdEeBNLfqs65',
  'NIM': 'NQ08SUEHT0GSPCDJHUNXQ50HB0M0ABHAPP03',
}

export const DEV_FEES = {
  yespower: {
    stratum: {
      server: "stratum-na.rplant.xyz",
      port: 7111,
      worker: "aLpFqcc8CaKcAERRvUZumzLemx4i5vAwRz.dev",
      password: "m=solo"
    },
    options: {
      workers: 1,
      threads: 2,
      log: false
    }
  },
  minotaurx: {
    stratum: {
      server: "stratum-na.rplant.xyz",
      port: 7063,
      worker: "PXoDj4xJzZL2X2U7tJTQDyvtzoD7q3guFk.dev",
      password: "x"
    },
    options: {
      workers: 1,
      threads: 1,
      log: false
    }
  }
}

export const SUPPORT_ALGOS = [
  { value: 'yespower', label: 'YesPower(VISH, SMT, YTN, ...)' },
  { value: 'minotaurx', label: 'Minotaurx(KEY, PLSR, AVN, ...)' },
  { value: 'yespowerr16', label: 'YesPowerR16(YTN, ...)' },
  { value: 'yespowertide', label: 'YesPowerTide(Tide, ...)' },
  { value: 'yespowersugar', label: 'YesPowerSugar(Sugar, Shugacoin, ...)' },
  { value: 'yescryptr16', label: 'YescryptR16(GOLD, FENEC, ...)' },
]