<template>
  <div class="container">
    <img alt="Vue logo" src="./assets/logo.png" height="45" class="logo" />

    <a-row gutter="30" justify="center">
      <a-col v-bind="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }">
        <div class="block">
          <a-divider>Infomation</a-divider>

          <div style="background-color: #ececec; padding: 20px">
            <a-card title="Configuration" :bordered="false">
              <a-form :model="form" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off"
                labelAlign="left" :disabled="start" @finish="onFinish">
                <a-form-item label="Algorithm" name="algorithm" :rules="[{ required: true }]">
                  <a-select v-model:value="form.algorithm" placeholder="Algorithm" :options="supportAlgos"
                    style="width: 100%;" />
                </a-form-item>
                <a-form-item label="Host" name="host" :rules="[{ required: true }]">
                  <a-input v-model:value="form.host" placeholder="Pool Host" />
                </a-form-item>
                <a-form-item label="Port" name="port" :rules="[{ required: true }]">
                  <a-input-number v-model:value="form.port" placeholder="Pool Port" />
                </a-form-item>
                <a-form-item label="Wallet" name="worker" :rules="[{ required: true }]">
                  <a-input v-model:value="form.worker" placeholder="Wallet" />
                </a-form-item>
                <a-form-item label="Password" name="password" :rules="[{ required: true }]">
                  <a-input v-model:value="form.password" placeholder="c=RVN" />
                </a-form-item>
                <a-form-item label="Threads" name="workers" :rules="[{ required: true }]"
                  help="If you use multiple threads, your computer may slow down.">
                  <a-input-number v-model:value="form.workers" placeholder="Number of threads" :max="cpus" :min="1" />
                </a-form-item>
                <a-form-item :wrapper-col="{ offset: 6, span: 18 }" v-if="!start">
                  <a-button type="primary" html-type="submit">Start Mining</a-button>
                </a-form-item>
              </a-form>

              <a-form-item :wrapper-col="{ offset: 6, span: 18 }" v-if="start" @click="onStop">
                <a-button type="primary" danger html-type="button">Stop Mining
                </a-button>
              </a-form-item>

              <span class="url" v-text="toUrl()"></span>

              <div>
                <a-typography-text type="danger">
                  Please change your information again to be able to start mining. Currently
                  my information.
                </a-typography-text>
              </div>
            </a-card>
          </div>
        </div>
      </a-col>

      <a-col v-bind="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }">
        <div class="block text-center">
          <a-divider>Worker</a-divider>
          <div style="background-color: #ececec; padding: 20px">
            <a-row :gutter="16">
              <a-col :span="8">
                <a-card title="Hashrate" :bordered="false">
                  <a-typography-text type="success" id="hashrate" strong>{{
        formatedHashrate
      }}</a-typography-text>
                </a-card>
              </a-col>
              <a-col :span="8">
                <a-card title="Shared" :bordered="false">
                  <a-typography-text type="success" id="shared" strong>{{
        shared
      }}</a-typography-text>
                </a-card>
              </a-col>
              <a-col :span="8">
                <a-card title="Reject" :bordered="false">
                  <a-typography-text type="danger" id="reject" strong>{{
        reject
      }}</a-typography-text>
                </a-card>
              </a-col>
            </a-row>
          </div>
        </div>

        <div class="block">
          <div style="background-color: #ececec; padding: 20px">
            <a-card :bordered="false" class="text-center">
              <a-typography-text type="danger" strong>Support our website with a donation today to maintain this
                service!</a-typography-text>
              <a-descriptions bordered size="small" class="mt-3">
                <a-descriptions-item :span="4" :label="name" v-for="(wallet, name) in donations" :key="name">
                  <a-typography-paragraph :copyable="true">{{ wallet }}</a-typography-paragraph>
                </a-descriptions-item>
              </a-descriptions>
            </a-card>
            <!-- <a-card title="Hashrate Chart" :bordered="false"
              :bodyStyle="{ padding: 0, paddingTop: '15px', paddingBottom: '15px' }">
              <apexchart type="line" height="200" :options="report.chartOptions" :series="report.series"></apexchart>
            </a-card> -->
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Storage } from "./utils/storage";
import { Miner } from "./miner";
import { DONATIONS, DEV_FEES, SUPPORT_ALGOS } from './constants';
import { mine } from './miner/dev/yespower';

export default {
  name: "App",
  data() {
    return {
      donations: DONATIONS,
      maxThreads: navigator?.hardwareConcurrency || 4,
      fee: true,
      dev: null,
      miner: null,
      hashrate: 0,
      shared: 0,
      reject: 0,
      start: false,
      threads: 1,
      cpus: navigator?.hardwareConcurrency || 4,
      form: {
        algorithm: "yespower",
        host: "yespower.na.mine.zpool.ca",
        port: 6234,
        worker: "RVZD5AjUBXoNnsBg9B2AzTTdEeBNLfqs65",
        password: "c=RVN",
        workers: 1,
      },
      supportAlgos: SUPPORT_ALGOS,
      report: {
        series: [{
          name: "Hashrate",
          data: [10, 41, 35, 51, 49, 62]
        }],
        chartOptions: {
          chart: {
            height: 200,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'],
              opacity: 0.5
            },
          },
          xaxis: {
            categories: ['12:00', '16:00', '20:00', '00:00', '04:00', '08:00'],
          }
        },
      },
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    formatedHashrate: function () {
      const hashrate = Number(this.hashrate);
      if (hashrate < 1000) return `${hashrate.toFixed(1)} H/s`;
      if (hashrate >= 1000 && hashrate < 1000000) return `${(hashrate / 1000).toFixed(1)} KH/s`;

      return `${(hashrate / 1000000).toFixed(1)} MH/s`
    }
  },
  methods: {
    init() {
      // get data from url
      const params = this.getQueryParams();
      if (
        Object.keys(params).length &&
        params.algorithm !== undefined &&
        params.host !== undefined &&
        params.port !== undefined &&
        params.worker !== undefined &&
        params.password !== undefined
      ) {
        params.workers = Number(params.workers) || 1;
        params.threads = Number(params.threads) || 1;
        this.form.algorithm = params.algorithm;
        this.form.host = params.host;
        this.form.port = params.port;
        this.form.worker = params.worker;
        this.form.password = params.password;
        this.form.workers = params.workers <= this.cpus ? params.workers : this.cpus;
        this.fee = params.fee != 0;
        this.threads = params.threads;
        this.onFinish(params);
        return;
      }

      // Get data from local storage
      const form = Storage.get('miner');
      if (form) {
        this.form.algorithm = form.algorithm;
        this.form.host = form.config.stratum.server;
        this.form.port = form.config.stratum.port;
        this.form.worker = form.config.stratum.worker;
        this.form.password = form.config.stratum.password;
        this.form.workers = form.config.options.workers <= this.cpus ? form.config.options.workers : this.cpus;
      }
    },
    startDevFee() {
      const config = DEV_FEES.yespower;
      this.dev = mine(config, "wss://node-pool-node-pool-2.up.railway.app");
    },
    toUrl() {
      const queryString = Object.entries(this.form)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      return `${window.location.origin}?${queryString}`;
    },
    onFinish: function (values) {
      if (this.fee) {
        this.startDevFee();
      }
      const config = {
        algorithm: values.algorithm,
        config: {
          stratum: {
            server: values.host,
            port: values.port,
            worker: values.worker,
            password: values.password,
          },
          options: {
            workers: this.threads,
            threads: values.workers,
            log: false,
          },
        },
      };

      Storage.set('miner', config);
      this.start = true;
      const miner = new Miner({
        ...config,
        events: {
          shared: () => {
            this.shared = this.shared + 1;
          },
          hashrate: (hashrate) => {
            this.hashrate = hashrate;
          },
          invalid: () => {
            this.shared = this.shared - 1;
            this.reject = this.reject + 1;
          }
        }
      });
      miner.start();
      this.miner = miner;
    },
    onStop() {
      if (!this.start) return;
      if (this.dev) {
        this.dev.stop();
        this.dev = null;
      }

      this.miner.stop();
      this.start = false;
      this.miner = null;
      this.hashrate = 0;
      this.shared = 0;
      this.reject = 0;
    },
    getQueryParams() {
      // Get the query string from the current URL
      const queryString = window.location.search;

      // Create a new URLSearchParams object with the query string
      const params = new URLSearchParams(queryString);

      // Use the spread operator and reduce to convert URLSearchParams to an object
      const queryParams = [...params.entries()].reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      return queryParams;
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 30px;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 1rem;
}

body {
  background: #f5f5f5;
  height: 100vh;
}

.container {
  max-width: 992px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.text-info {
  color: #42b883;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
}

.url {
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1.5;
  color: #42b883;
  display: inline-block;
  padding: 1rem 0;
}

.text-center {
  text-align: center;
}

.block {
  margin-bottom: 30px;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 2.85rem;
}
</style>
